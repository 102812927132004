import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import * as api from "../../../../services/index";
import toast from 'react-hot-toast';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeCheckoutPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const txnId = queryParams.get("txnId");

    // const fetchClientSecret = useCallback(async () => {
    //     let payload = {
    //         txnId,
    //         return_url: `http://localhost:3000/st/return?session_id={CHECKOUT_SESSION_ID}`,
    //         cancel_url:"http://localhost:3000/st/cancel?session_id={CHECKOUT_SESSION_ID}"
    //     }
    //     const response = await api.getPaymentIntent(payload);
    //     return response?.data?.clientSecret;
    // }, []);

    useEffect(async () => {
        try {
            let payload = {
                txnId,
                return_url: `https://store.leonard.com.my/st/return?session_id={CHECKOUT_SESSION_ID}`,
                cancel_url: "https://store.leonard.com.my/st/cancel?session_id={CHECKOUT_SESSION_ID}"
                // return_url: `http://localhost:3000/st/return?session_id={CHECKOUT_SESSION_ID}`,
                // cancel_url: "http://localhost:3000/st/cancel?session_id={CHECKOUT_SESSION_ID}"
            }
            const response = await api.getPaymentIntent(payload);
            window.location.href = response?.data?.clientSecret
        }
        catch (error) {
            toast.error(error?.response?.data?.message)
        }
    }, []);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100">
            <div>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div>
                <p className="text-muted">Please wait, we are processing your request...</p>
            </div>
        </div>
    )
}

export default StripeCheckoutPage;