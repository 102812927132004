import React from 'react';
import { Link } from 'react-router-dom';
import { BsFiletypePdf } from "react-icons/bs";



const Publications = () => {

    const baseSiteUrl = process.env.REACT_APP_PHOTO_URL ?? "http://leonardshop.locale/uploads";

    return (
        <div>
            <div className="headingBox">
                <div className="container">
                    <div className="mainHeading">
                        <article className="mainHeading__text">
                            <p className="mainHeading__preTitle">list of</p>
                            <h2 className="mainHeading__title">LPI - Related Publications</h2>
                        </article>
                    </div>
                </div>
            </div>

            <div className="container my-5">
                <div className="row">
                    <div className="col-sm-12 mb-2">
                        <Link to="/trainers"> Click here to view a list of  Licensed LPI Trainers</Link>
                    </div>
                    <div className="col-sm-12">
                        Listed below are publications involving the LEONARD Personality Inventory (LPI) and the Yong EQ Inventory.
                    </div>
                </div>
                <div></div>
                <div className="row  my-5">
                    <div className="col-sm-12">
                        <div className="st-box overflow-x-auto">
                            <table className='st-table' id='publication-table'>
                                <thead>
                                    <tr>
                                        <th width="10%">No.</th>
                                        <th width="20%">Name</th>
                                        <th width="55%">Title</th>
                                        <th width="15%">Download</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>1.</td>
                                        <td>Tan Wee Wah</td>
                                        <td>Emotional Intelligence and Leadership Development</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Tan Wee Wah - EQ and Leadership Development.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download </span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td> Dr Leonard Yong</td>
                                        <td>Yong EQ Inventory: Norms &amp; Technical Manual</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Yong EQ Inventory Norms Technical Manual.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td> Leonard Personality Incorporated</td>
                                        <td>Norms &amp; Technical Manual: The Leonard Pe`rsonality Inventory
                                            (LPI) </td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Norms_Technical Manual LPI.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td> Augustine Tan</td>
                                        <td>Validation of Emotional Excellence Questionnaire</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Validation of EE Questionnaire.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td> Kenneth Phun Thean Ming</td>
                                        <td>Personality Profiles of Lecturers In A Private College:
                                            An Invesitgation Based on Leonard Personality Inventory Unpublished
                                            Master in Education Project Paper, University of Malaya, 2002.</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>Kalai Selvi a/p Gopal</td>
                                        <td>

                                            <Link to="http://harvester.um.edu.my/index.php/record/view/3850">Personality Profiles of Teachers In A Secondary School In
                                                Petaling Jaya</Link> Unpublished Master in Education Project Paper,
                                            University of Malaya, 2001.</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>7.</td>
                                        <td>Dr Leonard Yong</td>
                                        <td><p>Emotional signs, Star interview with Dr. Leonard Yong
                                            regarding Nescafe Kick-Start TV Programme,<br />
                                            <em> Star Newspaper 15 September 2004, Youth section page
                                                12 </em> </p>
                                        </td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/LY The Star 150904.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8.</td>
                                        <td>Dr Leonard Yong</td>
                                        <td>Interview with Dr. Leonard Yong on the use of Leonard Personality
                                            Inventory<br />
                                            <em>Cosmo Magazine, 4 September 2004, Section 2 page 29 </em></td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/LY Kosmos.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9.</td>
                                        <td>Thanayot Sumalrot</td>
                                        <td>Abstract: The LPI profiles of Thais
                                            working-aged people in Bangkok's suburban and agricultural
                                            area</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Abstract suburban.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10.</td>
                                        <td>Siriprabha Kaewsrinual</td>
                                        <td>Abstract: The LPI profiles of Thais working-aged people in
                                            Bangkok's residential suburban area</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Abstract residential.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11.</td>
                                        <td>Maliwan Rueankam</td>
                                        <td>Abstract: The LPI profiles of Thais
                                            working-aged people in Bangkok's conservative area</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Abstract conservative.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12.</td>
                                        <td>Onvisa Kitpituck</td>
                                        <td>Abstract: The LPI profiles of Thais working-aged people in
                                            Bangkok's commercial and industrial area</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Abstract commercial.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>13.</td>
                                        <td>Navamani A/P Nagappon</td>
                                        <td>Clinical Case Study</td>
                                        <td>
                                            <Link to={`${baseSiteUrl}publications/pdf/Nava_Clinical Case Study 0317.pdf`} target="_blank" className='dwn-link' rel="noopener noreferrer">
                                                <BsFiletypePdf />
                                                <span>Download</span>
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Publications